<template>
  <div v-scroll="onScroll">
    <SystemItem
      class="mb-3"
      v-for="(item, i) in list"
      :key="i"
      :messageRecord="item"
    />
    <loading :isShowLoading="isShowLoading" ></loading>
  </div>
   
</template>

<script lang='js'>
import SystemItem from './components/SystemItem.vue';
import Loading from "@/components/loading.vue";
import { getSettingArr } from "@/utils/helper";
import  api  from "@/api"
export default {
  name: 'Notifications',
  components: { SystemItem ,Loading},
  props: {},
  data: function() {
    return {
    list: [],
      pageNumber: 0,
      pageSize: 20,
      totalPage: false,
       isShowLoading: false,
       isLoading :false
    };
  },
  methods:{

   
    async getSysNews(){
     
        if(this.isLoading)
      {
        return;
      }
      this.isLoading = true
      this.pageNumber++;
      this.isShowLoading = true;
      let params={
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        owner:this.$store.state.did,
        pushType:2,
         pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      let {list, totalPage} = res.data
      this.totalPage = totalPage;
      	if (this.pageNumber == 1) {
							this.list = list;
						} else {
							this.list = this.list.concat(list);
          }
       this.isShowLoading = false;
       this.isLoading = false
    },
   
    async onScroll(e) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = e.target.scrollingElement;
      if (
          scrollTop + clientHeight  >= scrollHeight &&
          this.totalPage > this.pageNumber
      ) {
        await this.getSysNews();
      }
    },
    onLangChange(){
   
       this.pageNumber =0
       this.getSysNews()
    },
  },
  mounted(){
    window.eventBus.$on('LangChange', this.onLangChange);
    this.getSysNews()
  }
};

</script>

<style lang='scss' scoped></style>
