import { render, staticRenderFns } from "./SystemItem.vue?vue&type=template&id=1508b682&scoped=true&"
import script from "./SystemItem.vue?vue&type=script&lang=js&"
export * from "./SystemItem.vue?vue&type=script&lang=js&"
import style0 from "./SystemItem.vue?vue&type=style&index=0&id=1508b682&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1508b682",
  null
  
)

export default component.exports